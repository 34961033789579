import cn from 'classnames';
import * as React from 'react';

import BaseInput from './BaseInput';
import CreditCardInput from './CreditCardInput';

const Input = ({ field, secondaryMessage = null, inputClassName = null, center = false, cc, ...props }) => (
  <div className={cn(center && props.inPlaceError ? '!pt-2' : '', 'flex w-full flex-col', props.className)}>
    {cc ? (
      <CreditCardInput className={inputClassName} {...field} {...props} />
    ) : (
      <BaseInput className={inputClassName} {...field} {...props} />
    )}
    {!!secondaryMessage && (
      <div className={cn('font-hvThin text-xs text-gray-500', !props.inPlaceError ? 'mt-2' : '')}>
        {secondaryMessage}
      </div>
    )}
  </div>
);

export default Input;
