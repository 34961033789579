import { FaCcAmex } from '@react-icons/all-files/fa/FaCcAmex';
import { FaCcDinersClub } from '@react-icons/all-files/fa/FaCcDinersClub';
import { FaCcDiscover } from '@react-icons/all-files/fa/FaCcDiscover';
import { FaCcJcb } from '@react-icons/all-files/fa/FaCcJcb';
import { FaCcMastercard } from '@react-icons/all-files/fa/FaCcMastercard';
import { FaCcVisa } from '@react-icons/all-files/fa/FaCcVisa';
import { FaCreditCard } from '@react-icons/all-files/fa/FaCreditCard';
import * as React from 'react';

import AbstractInput, { BaseInputProps } from '../AbstractInput/AbstractInput';
import { getCreditCardProcessorByNumber } from 'utils/cc';
import Loader from 'styleguide/components/Loader/Loader';

let Cleave = null;
const icons = {
  'american-express': FaCcAmex,
  mastercard: FaCcMastercard,
  visa: FaCcVisa,
  'diners-club': FaCcDinersClub,
  discover: FaCcDiscover,
  jcb: FaCcJcb,
};

export const getCreditCardIconByProcessor = (processor: string) =>
  icons[processor] ? icons[processor] : FaCreditCard;

export const getCreditCardIconByNumber = (ccNumber?: string) => {
  let processor: string;
  if (typeof ccNumber === 'string' && ccNumber) {
    processor = getCreditCardProcessorByNumber(ccNumber);
  } else {
    processor = getCreditCardProcessorByNumber(null);
  }
  return getCreditCardIconByProcessor(processor);
};

const CreditCardInput = ({ className, ...otherProps }: BaseInputProps) => {
  const [cleaveLoaded, setCleaveLoaded] = React.useState(false);

  React.useEffect(() => {
    import('cleave.js/react').then(CleaveModule => {
      Cleave = CleaveModule.default;
      setCleaveLoaded(true);
    });
  }, []);

  if (!cleaveLoaded) {
    return <Loader className="mb-4" />;
  }

  return (
    <AbstractInput
      component={Cleave}
      // options={{ creditCard: true }}
      className={className}
      Icon={getCreditCardIconByNumber(otherProps.value)}
      iconPlacement="left"
      {...otherProps}
      iconClassName="w-4 h-4"
      type="tel"
      {...otherProps}
    />
  );
};

export default CreditCardInput;
