import * as React from 'react';
import cn from 'classnames';
import css from './InPlaceMessage.scss';
import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle';
import { IconTooltipQuestion } from 'styleguide/icons';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  messageType: 'info' | 'warning' | 'error';
  message?: string;
  touched?: boolean;
  requireTouch?: boolean;
}

const InPlaceMessage = ({
  messageType = 'info',
  message,
  touched = false,
  requireTouch = true,
  children,
  className,
  ...props
}: Props) => (
  <div
    className={cn(
      messageType === 'error' ? 'font-hvBold' : 'font-normal',
      css.message,
      css[`message--${messageType}`],
      messageType === 'info' && 'text-xs leading-4 text-gray-500',
      className,
    )}
    {...props}
  >
    {(!requireTouch || touched) && message ? (
      <div className="mt-[3px] flex">
        {messageType === 'error' && (
          <FaExclamationTriangle className="ml-1 mr-1 h-4 w-4 transform text-red-700 transition duration-150" />
        )}
        {messageType === 'info' && (
          <IconTooltipQuestion size="sm" className="mr-1 transform transition duration-150" />
        )}
        <div className="flex items-end">{message}</div>
      </div>
    ) : null}
    {children}
  </div>
);

export default InPlaceMessage;
